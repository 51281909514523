var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "bgFFF",
            attrs: { type: "card" },
            on: { "tab-click": _vm.handleTab },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          [
            _vm.authority.tabs["payRepeat1"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "重复支付", name: "1" } },
                  [_c("payRepeatList", { ref: "payRepeatList" })],
                  1
                )
              : _vm._e(),
            _vm.authority.tabs["payRepeat2"]
              ? _c(
                  "el-tab-pane",
                  { attrs: { label: "退款记录", name: "2" } },
                  [_c("payRepeatRefund", { ref: "payRepeatRefund" })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }